<template>
  <div class="dragoverlay" v-if="drag.active">
    <Idea ghost
      :graph="cursorOver === 'graph'"
      :idea="ideas[drag.ideaId]"
      :style="`transform: translate(${drag.x}px, ${drag.y}px)`"
      :cursorScore="cursorScore"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import Idea from './Idea.vue'

export default Vue.extend({
  components: {
    Idea
  },
  computed: {
    ...mapState('roomUi', ['drag']),
    ...mapState('room', ['ideas']),
    ...mapGetters('roomUi', ['cursorScore', 'cursorOver'])
  }
})
</script>

<style lang="scss">
.dragoverlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
}
</style>
