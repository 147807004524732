






























import Vue, { PropOptions } from 'vue'
import mixColor from 'mix-color'
import { scaleImpactLabel, scaleQuicknessLabel, getScoreAvgColor } from '@/store/room-ui'
import store from '@/store'
import * as t from '@/types'

export default Vue.extend({
  props: {
    graph: Boolean,
    draggable: Boolean,
    ghost: Boolean,
    hideOnDrag: Boolean,
    cursorScore: Object as PropOptions<t.IdeaScore>,
    idea: {
      type: Object,
      required: true
    } as PropOptions<t.Idea>
  },
  data: () => ({
    draggingXOffset: 0,
    draggingYOffset: 0,
    isDragging: false,
    raf: 0,
  }),
  computed: {
    style() {
      if (!this.graph) return
      const color = getScoreAvgColor(this.cursorScore || this.idea.myScore)
      return {
        borderColor: color,
        backgroundColor: color && mixColor(color, '#191A1B', 0.9)
      }
    }
  },
  methods: {
    mousedown(e: MouseEvent) {
      if (!this.draggable) return

      this.isDragging = true
      this.setEventListeners()
      const rect: DOMRect = this.$el.getBoundingClientRect()
      this.draggingXOffset = e.x - rect.x
      this.draggingYOffset = e.y - rect.y

      store.commit('roomUi/dragStart', {
        // @ts-ignore
        width: this.$el.offsetWidth,
        // @ts-ignore
        height: this.$el.offsetHeight,
        // @ts-ignore
        ideaId: this.idea.id
      })
      this.mousemove(e)
    },
    mouseup() {
      this.isDragging = false
      this.removeEventListeners()
      store.dispatch('roomUi/dragStop')
    },
    mousemove(e: MouseEvent) {
      this.raf = window.requestAnimationFrame(() => {
        const mouse: t.Point = { x: e.x, y: e.y }
        const relative: t.Point = {
          x: e.x - this.draggingXOffset,
          y: e.y - this.draggingYOffset
        }
        store.commit('roomUi/dragMove', { mouse, relative })
      })
    },
    keydown(e: KeyboardEvent) {
      if (e.key !== 'Escape') return
      this.isDragging = false
      this.removeEventListeners()
      store.commit('roomUi/setDragActive', false)
    },
    setEventListeners() {
      window.addEventListener('mouseup', this.mouseup)
      window.addEventListener('mousemove', this.mousemove)
      window.addEventListener('keydown', this.keydown)
    },
    removeEventListeners() {
      window.removeEventListener('mouseup', this.mouseup)
      window.removeEventListener('mousemove', this.mousemove)
      window.removeEventListener('keydown', this.keydown)
    },
    scaleImpactLabel(value: number) {
      return scaleImpactLabel(value)
    },
    scaleQuicknessLabel(value: number) {
      return scaleQuicknessLabel(value)
    },
    getScoreAvgColor(value: t.IdeaScore) {
      return getScoreAvgColor(value)
    }
  }
})
