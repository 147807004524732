const useProdApi = process.env.VUE_APP_PROD_API === 'true'

export const isDev = process.env.NODE_ENV !== 'production'

export const apiUrl = (isDev && !useProdApi) ?
  'http://localhost:8081' :
  'https://kaizen.bar'

export const ioUrl = (isDev && !useProdApi) ?
  'localhost:8081' :
  'kaizen.bar'
