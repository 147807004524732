


























import Vue from 'vue'
import api from '@/modules/api'

import NavBar from '@/components/NavBar.vue'

export default Vue.extend({
  components: {
    NavBar
  },
  data: () => ({
    name: ''
  }),
  methods: {
    submit() {
      api.post('/teams', { name: this.name })
        .then(({ data }) => {
          if (data.error) return this.$buefy.toast.open({
            message: data.error,
            position: 'is-bottom',
            type: 'is-warning'
          })
          if (data.id) {
            this.$router.push(`/teams/${data.id}`)
          }
        })
        .catch((err) => {
          console.warn(err)
          this.$buefy.toast.open({
            message: `Something's not good.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  }
})
