var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"x-axis"},[_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v("Long project")]),_c('span',[_vm._v("Quick project")]),(_vm.cursor && _vm.cursorOver === 'graph')?[_c('div',{staticClass:"cursor-shine-x",style:({
          transform: ("translateX(" + (_vm.xScale(_vm.cursor.x) - 50) + "px)"),
          background: ("linear-gradient(to right, transparent, " + _vm.cursorColor + ", transparent)")
        })}),_c('div',{staticClass:"cursor",style:({
          color: _vm.cursorColor,
          transform: ("translateX(calc(" + (Math.max(30, _vm.xScale(_vm.cursor.x))) + "px - 50%))")
        }),domProps:{"textContent":_vm._s(_vm.cursorLabels.quickness)}})]:_vm._e()],2),_c('div',{staticClass:"y-axis"},[_c('span',[_vm._v("Big impact")]),_c('span',{staticStyle:{"padding-bottom":"10px"}},[_vm._v("Low impact")]),(_vm.cursor && _vm.cursorOver === 'graph')?[_c('div',{staticClass:"cursor-shine-y",style:({
          transform: ("translateY(" + (_vm.yScale(_vm.cursor.y) - 50) + "px)"),
          background: ("linear-gradient(to bottom, transparent, " + _vm.cursorColor + ", transparent)")
        })}),_c('div',{staticClass:"cursor",style:({
          color: _vm.cursorColor,
          transform: ("translateY(calc(" + (_vm.yScale(_vm.cursor.y)) + "px - 50%))")
        }),domProps:{"textContent":_vm._s(_vm.cursorLabels.impact)}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }