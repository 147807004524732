var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"graph",class:{ 'is-dragging-over': _vm.cursor }},[_c('div',{ref:"gc",staticClass:"graph-content"},[_c('GraphAxes'),(_vm.cursor && _vm.cursorOver === 'graph')?[_c('div',{staticClass:"x-cursor-line",style:({
          left: _vm.xScale(_vm.cursor.x) + 'px',
          top: _vm.yScale(_vm.cursor.y) + 'px',
          height: _vm.rect.height - _vm.yScale(_vm.cursor.y) + 'px',
          background: _vm.cursorColor
        })}),_c('div',{staticClass:"y-cursor-line",style:({
          left: 0,
          top: _vm.yScale(_vm.cursor.y) + 'px',
          width: _vm.xScale(_vm.cursor.x) + 'px',
          background: _vm.cursorColor
        })})]:_vm._e(),_vm._l((_vm.ideasOnGraph),function(idea){return _c('Idea',{key:idea.id,style:({
        zIndex: _vm.zIndices[idea.id],
        transform: _vm.ideaTransform(idea)
      }),attrs:{"draggable":"","hide-on-drag":"","graph":"","idea":idea}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }