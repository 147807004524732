





























import { mapState } from 'vuex'
import Vue from 'vue'
import api from '@/modules/api'

import NavBar from '@/components/NavBar.vue'

export default Vue.extend({
  components: {
    NavBar
  },
  data: () => ({
    isLoading: true,
    isJoining: false,
    team: {},
    host: {},
    email: ''
  }),
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    const { token } = this.$route.params

    this.isLoading = true
    api.get('/teams/invites/' + token)
      .then(({ data }) => {
        this.email = data.email
        this.team = data.team
        this.host = data.host
      })
      .catch((err) => {
        console.warn(err)
        this.$buefy.toast.open({
          message: `Something's not good.`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    join() {
      const { token } = this.$route.params

      this.isJoining = true
      api.post('/teams/join', { token })
        .then(({ data }) => {
          this.$router.push(`/teams/${data.team_id}`)
        })
        .catch((e) => {
          console.error(e)
          this.$buefy.toast.open({
            message: `Something's not good.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
        .finally(() => this.isJoining = false)
    }
  }
})
