import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'

import Home from '../pages/home/Home.vue'
import Room from '../pages/room/Room.vue'
import Auth from '../pages/auth/Auth.vue'

import TeamCreate from '../pages/teams/TeamCreate.vue'
import TeamHome from '../pages/teams/TeamHome.vue'
import TeamJoin from '../pages/teams/TeamJoin.vue'

import RoomCreate from '../pages/room/RoomCreate.vue'

import NotFound from '@/components/NotFound.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: Auth
  },
  {
    path: '/teams/create',
    component: TeamCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/teams/:teamId',
    component: TeamHome,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/teams/invites/:token',
    component: TeamJoin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rooms/create',
    component: RoomCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rooms/:id',
    component: Room,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('fetchUser').then((user) => {
      if (user) return next()
      return user ? next() : next('/login?r=' + encodeURIComponent(to.path))
    })
  } else {
    next()
  }
})

export default router
