















































import Vue from 'vue'
import { formatDistanceToNow } from 'date-fns'
import NavBar from '@/components/NavBar.vue'
import api from '@/modules/api'
import TeamHeader from '@/components/TeamHeader.vue'
import NotFound from '@/components/NotFound.vue'

export default Vue.extend({
  components: {
    NavBar,
    TeamHeader,
    NotFound
  },
  data: () => ({
    id: '',
    name: '',
    rooms: [],
    members: [],
    creating: false,
    fetching: false,
    notFound: false
  }),
  computed: {
    teamId(): string {
      return this.$route.params.teamId
    }
  },
  watch: {
    teamId: {
      handler() {
        this.fetchRoom()
      },
      immediate: true
    }
  },
  methods: {
    fetchRoom() {
      this.fetching = true
      api.get(`/teams/${this.teamId}`)
        .then(({ data }) => {
          this.id = data.id
          this.name = data.name
          this.rooms = data.rooms
          this.members = data.members
        })
        .catch(() => {
          this.notFound = true
        })
        .finally(() => {
          this.fetching = false
        })
    },
    createRoom() {
      this.creating = true
      api.post(`/rooms`, { team_id: this.teamId })
        .then(({ data }) => {
          this.$router.push(`/rooms/${data.id}`)
        })
        .catch((err) => {
          this.$buefy.toast.open('Error')
        })
        .finally(() => {
          this.creating = false
        })
    },
    // TODO: Make it a Vue filter
    formatDistanceToNow(date: string) {
      return formatDistanceToNow(new Date(date), { addSuffix: true })
    }
  }
})
