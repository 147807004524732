<template>
  <div>
    <b-loading />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>