




















import NavBar from '@/components/NavBar.vue';
import Vue from 'vue'
import { mapState } from 'vuex'

import api from '@/modules/api'

export default Vue.extend({
  components: { NavBar },
  data: () => ({
    teamName: ''
  }),
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    if (this.user.teams) {
      const lastTeam = Array.from<any>(this.user.teams).pop();
      this.$router.replace(`/teams/${lastTeam.id}`)
    }
  },
  methods: {
    submit() {
      api.post('/teams', { name: this.teamName })
        .then(({ data }) => {
          this.$router.replace(`/teams/${data.id}`)
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: `Something's not good.`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  }
})
