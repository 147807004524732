











import Vue from 'vue'
import RoomBrainstorm from './brainstorm/RoomBrainstorm.vue'
import RoomGraph from './graph/RoomGraph.vue'
import RoomHeader from './components/RoomHeader.vue'
import RoomDragOverlay from './components/RoomDragOverlay.vue'
import { mapState } from 'vuex'

import * as t from '@/types'

interface MsgAddIdea {
  idea: t.Idea
}

export default Vue.extend({
  components: {
    RoomBrainstorm,
    RoomGraph,
    RoomHeader,
    RoomDragOverlay
  },
  computed: {
    ...mapState('room', ['fetched'])
  },
  mounted() {
    const roomId: t.RoomId = this.$route.params.id
    this.$store.dispatch('room/initialize', roomId)
  },
  destroyed() {
    this.$store.dispatch('room/close')
  }
})
