<template>
  <b-navbar shadow wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <h1 class="is-size-4 has-text-weight-bold">Kaizen.bar</h1>
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-dropdown right arrowless v-if="user">
        <template slot="label">
          <b>{{ user.name || user.email }}</b>
          <figure v-if="user.photo_url" class="ml-3 image is-24x24">
            <img class="is-rounded" :src="user.photo_url">
          </figure>
        </template>
        <b-navbar-item href="#">
          Preferences
        </b-navbar-item>
        <b-navbar-item :href="`${apiUrl}/auth/logout`">
          Log out
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
    </b-navbar>
</template>

<script>
import { mapState } from 'vuex'
import { apiUrl } from '@/constants'

export default {
  data: () => ({
    apiUrl,
  }),
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style lang="scss">

</style>
