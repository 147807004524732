















































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters('roomUi', ['cursorOver', 'cursor', 'xScale', 'yScale', 'cursorColor', 'cursorLabels'])
  }
})
