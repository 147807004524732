import Vue from 'vue'
import Vuex from 'vuex'

import room from './room'
import roomUi from './room-ui'

import api from '@/modules/api'

import * as t from '../types'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    room,
    roomUi
  },
  state: ({
    user: undefined,
    socketToken: undefined
  } as t.RootState),
  mutations: {
    setUser(state, payload) {
      Vue.set(state, 'user', payload)
      state.stateFetched = true
    },
    setSocketToken(state, payload) {
      Vue.set(state, 'socketToken', payload)
    }
  },
  actions: {
    async fetchUser({ commit, state }) {
      if (state.user) {
        return state.user
      }
      const { data } = await api.get('/user')

      if (data.user) {
        commit('setUser', data.user)
        commit('setSocketToken', data.socketToken)
        return data.user
      } else {
        return false
      }
    }
  }
})
