





































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import Idea from '../components/Idea.vue'
import GraphAxes from './GraphAxes.vue'

import * as t from '@/types'

export default Vue.extend({
  components: {
    Idea,
    GraphAxes
  },
  data: () => ({
    resizeObserver: null as any
  }),
  computed: {
    ...mapGetters('roomUi', ['cursor', 'cursorOver', 'xScale', 'yScale', 'cursorColor', 'ideasOnGraph']),
    ...mapState(['draggingIdea', 'graph']),
    ...mapState('room', ['ideas']),
    ...mapState('roomUi', {
      rect: (state: any) => state.panes.graph.rect,
      drag: (state: any) => state.drag,
      zIndices: (state: any) => state.panes.graph.zIndices
    }),
  },
  methods: {
    ideaTransform(idea: t.Idea): string {
      if (!idea.myScore) return ''
      const x = `calc(${this.xScale(idea.myScore.quickness)}px - ${idea.myScore.quickness}%)`
      const y = `calc(${this.yScale(idea.myScore.impact)}px - ${100 - idea.myScore.impact}%)`
      return `translate(${x}, ${y})`
    }
  },
  mounted() {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver(([entry]: any) => {
      if (entry && entry.contentRect) {
        const domRect: DOMRect = (this.$refs.gc as Element).getBoundingClientRect()
        const rect: t.PaneRect = {
          left: domRect.left,
          top: domRect.top,
          right: domRect.right,
          bottom: domRect.bottom,
          width: domRect.width, // or entry.contentRect ?
          height: domRect.height
        }
        this.$store.commit('roomUi/setGraphRect', rect)
      }
    })
    this.resizeObserver.observe(this.$el)
  },
  destroyed() {
    this.resizeObserver.unobserve(this.$el)
  }
})
