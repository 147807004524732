import Vue from 'vue'
import { io } from 'socket.io-client'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import * as t from '@/types'
import { ioUrl } from '@/constants'

import api from '@/modules/api'

interface MsgAddIdea {
  idea: t.Idea
}

export default {
  namespaced: true,

  state: {
    fetched: false,
    name: '',
    id: '',
    owner: '',
    stage: null,
    ideas: {},
    participants: {},
    socket: null
  } as t.RoomState,

  getters: {
    participants(state: t.RoomState): [string, t.User][] {
      if (!state.participants) {
        return []
      }
      return Object.entries(state.participants)
        .sort((a, b) => new Date(a[1].created_at).getTime() - new Date(b[1].created_at).getTime())
    }
  },

  mutations: {
    reset(state: t.RoomState): void {
      state.fetched = false
      state.id = ''
      state.owner = ''
      state.stage = null
      state.ideas = {}
    },
    populate(state: t.RoomState, payload: t.Room): void {
      state.fetched = true
      state.id = payload.id
      state.owner = payload.owner
      state.stage = payload.stage
      if (payload.ideas) {
        payload.ideas.forEach(idea => {
          if (!idea.id) return
          Vue.set(state.ideas, idea.id, idea)
        })
      }
    },
    setStage(state: t.RoomState, payload: t.RoomStage): void {
      state.stage = payload
    },
    addIdea(state: t.RoomState, idea: t.Idea): void {
      if (!idea.id) return
      Vue.set(state.ideas, idea.id, idea)
    },
    deleteIdea(state: t.RoomState, id: string): void {
      Vue.delete(state.ideas, id)
    },
    setIdeaScore(state: t.RoomState, payload: { id: string, score: t.IdeaScore }): void {
      Vue.set(state.ideas[payload.id], 'myScore', payload.score)
    },
    setParticipants(state: t.RoomState, payload: Record<string, t.User>): void {
      Vue.set(state, 'participants', payload)
    }
  },

  actions: {
    initialize({ state, commit, rootState }: { state: t.RoomState, rootState: any, commit: any }, id: t.RoomId): void {
      commit('reset')
      api.get(`/rooms/${id}`)
        .then(({ data }) => {
          commit('populate', data)
        })
        .catch(error => {
          console.error(error)
        })

      state.socket = io(ioUrl)
      state.socket.connect()
      state.socket.emit('room:connect', { room_id: id, socketToken: rootState.socketToken })
      state.socket.on('room:error', (payload) => {
        Snackbar.open({ type: 'is-danger', message: 'Error: ' + payload })
      })
      state.socket.on('room:participants', (payload: Record<string, t.User>) => {
        commit('setParticipants', payload)
      })
      state.socket.on('room:addIdea', (payload) => {
        commit('addIdea', payload)
      })
      state.socket.on('connect', () => {
        console.log('socket connected', id)
      })
      state.socket.on('disconnect', (e) => {
        if (e === 'io client disconnect') return
        Snackbar.open({ type: 'is-danger', message: 'Disconnected from socket, please refresh the page' })
      })
    },

    close({ state }: { state: t.RoomState }): void {
      if (!state.socket) return
      state.socket.close()
    },

    // add/remove/edit idea
    addIdea({ state }: { state: t.RoomState }, idea: t.Idea): void {
      if (!state.socket) return
      const message: MsgAddIdea = { idea }
      state.socket.emit('room:addIdea', message)
    },
    setIdeaScore({ state, commit }: { state: t.RoomState, commit: any }, payload: { id: string, score: t.IdeaScore }): void {
      if (!state.socket) return
      state.socket.emit('idea:setScore', payload)
      commit('setIdeaScore', payload)
    }
  }
}
