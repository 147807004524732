<template>
  <div>
    <nav-bar />
    <div class="section">
      <div class="container">
        <h1 class="is-size-1">4 ٠ 四</h1>
        <h1 class="is-size-1 has-text-weight-bold">Nothing here</h1>
        <b-button tag="a" href="/" class="mt-6" type="is-primary">Go home</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
export default {
  components: { NavBar },

}
</script>

<style>

</style>
