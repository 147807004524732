<template>
  <div class="team-header">
    <div class="container">
      <h3>Team</h3>

      <div class="is-flex is-align-items-center is-justify-content-space-between">
        <div class="is-flex is-align-items-center">
          <h2 class="title mr-3 is-size-2 mb-0 has-text-weight-bold">{{ teamName }}</h2>
          <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
              <b-button type="is-small" icon-right="chevron-down" />
            </template>
            <b-dropdown-item
              v-for="team in user.teams"
              :key="team.id"
              has-link
            >
              <router-link :to="`/teams/${team.id}`">{{ team.name }}</router-link>
            </b-dropdown-item>
            <b-dropdown-item separator />
            <b-dropdown-item aria-role="listitem">Team settings</b-dropdown-item>
            <b-dropdown-item separator />
            <b-dropdown-item has-link aria-role="listitem">
              <router-link to="/teams/create">
                Create new team
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="is-flex">

          <div v-for="member in teamMembers" :key="member.email" :title="member.name">
            <figure class="image avatar">
              <img v-if="member.photo_url" class="is-rounded" :src="member.photo_url">
              <div class="initials" v-else>{{ (member.name || member.email)[0] }}</div>
            </figure>
          </div>

          <b-dropdown position="is-bottom-left">
            <template #trigger>
              <b-button type="is-primary is-light ml-3" icon-left="account-plus-outline">Invite</b-button>
            </template>
            <b-dropdown-item custom :focusable="false" style="width: 300px">
              <form @submit.prevent="invite">
                <b-field label="Invite a teammate">
                  <b-input v-model="inviteEmail" size type="email" required placeholder="Email" />
                </b-field>
                <b-button v-if="!invited" :loading="inviting" native-type="submit" label="Invite" type="is-primary" icon-left="account-plus-outline" />
                <b-button v-else label="Invited" type="is-success is-outlined" icon-left="check" />
              </form>
            </b-dropdown-item>
          </b-dropdown>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/modules/api'

export default {
  props: {
    teamId: String,
    teamName: String,
    teamMembers: Array,
  },
  data: () => ({
    inviteEmail: '',
    inviting: false,
    invited: false
  }),
  watch: {
    inviteEmail() {
      this.invited = false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    invite() {
      this.inviting = true
      api.post(`/teams/${this.teamId}/invite`, { email: this.inviteEmail })
        .then(() => this.invited = true)
        .catch(console.error)
        .finally(() => this.inviting = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.team-header {
  background-color: #fff;
  height: 220px;
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}

.avatar {
  width: 45px;
  height: 45px;
  margin-top: -3px;
  margin-left: -10px;
  img {
    border: 3px solid #fff;
  }
  .initials {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    background-color: #eee;
    border: 3px solid #fff;
    text-align: center;
    line-height: 40px;
  }
}
</style>
