import Vue from 'vue'
import { scaleLinear, scaleQuantize } from 'd3'
import * as t from '@/types'

interface _ActionMethod {
  state: t.RoomUiState,
  commit: any,
  dispatch: any,
  getters: any
}

interface DragStartPayload {
  ideaId: string,
  width: number,
  height: number
}

interface DragMovePayload {
  relative: t.Point,
  mouse: t.Point
}

function overlapsPane(p: t.Point, rect: t.PaneRect) {
  return (
    p.x >= rect.left &&
    p.x <= rect.right &&
    p.y >= rect.top &&
    p.y <= rect.bottom
  )
}

function getScoreAvg(s: t.IdeaScore): number {
  return (s.impact + s.quickness) / 2
}

const scaleColor = scaleLinear(
  [0, 60, 100],
  ['#FF0F0F', '#FBFF2C', '#20E04A']
)

export const getScoreAvgColor = (s?: t.IdeaScore): string|undefined => {
  return s && scaleColor(getScoreAvg(s))
}

export const scaleImpactLabel = scaleQuantize(
  [0, 100],
  [
    'No difference',
    'Might help',
    'Good', 
    'Amazing',
    'We need it ASAP'
  ]
)

export const scaleQuicknessLabel = scaleQuantize(
  [0, 100],
  [
    '6+ months',
    '3-6 months',
    '1-3 months',
    '3-4 weeks',
    '2-3 weeks',
    '1-2 weeks',
    '2-5 days',
    '1-2 days',
    'Hours'
  ]
)

export default {
  namespaced: true,
  state: () => ({
    panes: {
      brainstorm: {
        rect: {}
      },
      graph: {
        rect: {},
        cursor: {},
        zIndices: {}
      },
    },
    drag: {
      rect: {},
      mouse: {}
    }
  } as t.RoomUiState),

  getters: {
    xScale(state: t.RoomUiState): any {
      return scaleLinear()
        .domain([0, 100])
        .range([0, state.panes.graph.rect.width])
        .clamp(true)
    },
    yScale(state: t.RoomUiState): any {
      return scaleLinear()
        .domain([100, 0])
        .range([0, state.panes.graph.rect.height])
        .clamp(true)
    },
    cursor(state: t.RoomUiState, getters: any): t.Point | null {
      if (!state.drag.active) return null
      const gr: t.PaneRect = state.panes.graph.rect
      const dr: t.PaneRect = state.drag.rect
      const xs = scaleLinear()
        .domain([-2, gr.width - dr.width + 2])
        .range([0, gr.width])
      const ys = scaleLinear()
        .domain([-2, gr.height - dr.height + 2])
        .range([0, gr.height])
      const p: t.Point = {
        x: Math.round(getters.xScale.invert(xs(state.drag.x - gr.left))),
        y: Math.round(getters.yScale.invert(ys(state.drag.y - gr.top))),
      }
      return p
    },
    cursorOver(state: t.RoomUiState): string {
      if (overlapsPane(state.drag.mouse, state.panes.brainstorm.rect)) {
        return 'brainstorm'
      } else if (overlapsPane(state.drag.mouse, state.panes.graph.rect)) {
        return 'graph'
      }
      return 'unknown'
    },
    cursorScore(state: t.RoomUiState, getters: any): t.IdeaScore | null {
      if (!getters.cursor || getters.cursorOver !== 'graph') return null
      const score: t.IdeaScore = {
        quickness: getters.cursor.x,
        impact: getters.cursor.y
      }
      return score
    },
    cursorColor(state: t.RoomUiState, getters: any): string | null {
    if (!getters.cursor || getters.cursorOver !== 'graph') return null
      const score: t.IdeaScore = {
        quickness: getters.cursor.x,
        impact: getters.cursor.y
      }
      return scaleColor(getScoreAvg(score))
    },
    cursorLabels(state: t.RoomUiState, getters: any) {
      if (!getters.cursor || getters.cursorOver !== 'graph') return null
      return {
        quickness: scaleQuicknessLabel(getters.cursor.x),
        impact: scaleImpactLabel(getters.cursor.y)
      }
    },
    ideasOnGraph(state: t.RoomUiState, getters: any, rootState: t.RootState): Array<t.Idea> {
      const ideas = rootState.room.ideas
      return Object.values(ideas)
        .filter((i: t.Idea) => i.averageScore || i.myScore)
    }
  },

  mutations: {
    setBrainstormRect(state: t.RoomUiState, rect: t.PaneRect) {
      Vue.set(state.panes.brainstorm, 'rect', rect)
    },
    setGraphRect(state: t.RoomUiState, rect: t.PaneRect) {
      Vue.set(state.panes.graph, 'rect', rect)
    },

    // Drag&Drop
    setDragActive(state: t.RoomUiState, payload: boolean) {
      Vue.set(state.drag, 'active', payload)
    },
    bringToFront(state: t.RoomUiState, ideaId: string) {
      const BASE_INDEX = 10
      const maxVal = Math.max(Math.max(...Object.values(state.panes.graph.zIndices)), BASE_INDEX)
      state.panes.graph.zIndices[ideaId] = maxVal + 1
    },
    dragStart(state: t.RoomUiState, payload: DragStartPayload) {
      Vue.set(state.drag, 'active', true)
      Vue.set(state.drag, 'ideaId', payload.ideaId)
      Vue.set(state.drag.rect, 'width', payload.width)
      Vue.set(state.drag.rect, 'height', payload.height)
    },
    dragMove(state: t.RoomUiState, payload: DragMovePayload) {
      Vue.set(state.drag, 'x', payload.relative.x)
      Vue.set(state.drag, 'y', payload.relative.y)
      Vue.set(state.drag, 'mouse', payload.mouse)
    }
  },

  actions: {
    dragStop({ state, commit, dispatch, getters }: _ActionMethod) {
      if (getters.cursorOver === 'brainstorm') {
        dispatch('room/setIdeaScore', { id: state.drag.ideaId, score: null }, { root: true })
      }
      if (getters.cursorOver === 'graph') {
        const score: t.IdeaScore = {
          quickness: getters.cursor.x,
          impact: getters.cursor.y
        }
        dispatch('room/setIdeaScore', { id: state.drag.ideaId, score }, { root: true })
        commit('bringToFront', state.drag.ideaId)
      }
      commit('setDragActive', false)
    }
  }
}
