














import Vue from 'vue'
import * as t from '@/types'

export default Vue.extend({
  name: 'BrainstormInput',
  data: () => ({
    input: ''
  }),
  methods: {
    submit() {
      if (this.input.length < 3) return
      const idea: t.Idea = {
        title: this.input
      }
      this.$store.dispatch('room/addIdea', idea)
      this.input = ''
    }
  }
})
