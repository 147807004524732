



















import Vue from 'vue'
import { apiUrl } from '@/constants'
import NavBar from '@/components/NavBar.vue'

export default Vue.extend({
  components: { NavBar },
  computed: {
    r() {
      return encodeURIComponent(String(this.$route.query?.r || ''))
    }
  },
  data: () => ({
    apiUrl
  })
})
