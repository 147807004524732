

















import Vue from 'vue'
import { mapState } from 'vuex'
import Idea from '../components/Idea.vue'
import BrainstormInput from './BrainstormInput.vue'
import * as t from '@/types'

import 'simplebar'
import 'simplebar/dist/simplebar.min.css'

export default Vue.extend({
  components: {
    Idea,
    BrainstormInput
  },
  data: () => ({
    resizeObserver: null
  }),
  computed: {
    ...mapState('room', ['ideas']),
    list() {
      const ideas: Record<string, t.Idea> = this.ideas
      return Object.values(ideas)
        .filter((i: t.Idea) => !i.myScore && !i.averageScore)
        .sort((a: t.Idea, b: t.Idea) => {
          if (!a.created_at || !b.created_at) return 0
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        })
    },
  },
  mounted() {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver(([entry]: any) => {
      if (entry && entry.contentRect) {
        const domRect: DOMRect = (this.$refs.panel as Element).getBoundingClientRect()
        const rect: t.PaneRect = {
          left: domRect.left,
          top: domRect.top,
          right: domRect.right,
          bottom: domRect.bottom,
          width: domRect.width, // or entry.contentRect ?
          height: domRect.height
        }
        this.$store.commit('roomUi/setBrainstormRect', rect)
      }
    })
    // @ts-ignore
    this.resizeObserver.observe(this.$el)
  },
  destroyed() {
    // @ts-ignore
    this.resizeObserver.unobserve(this.$el)
  }
})
